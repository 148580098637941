import React from 'react';
import './mainContent.css';


const MainContent = ({ currentUrl, isPhone, currentService, onHamburgerClick, traductions, onBackbuttonClick }) => {
  return (

      <div className="main-content">
      
          <div className="hamburger-icon" onClick={onHamburgerClick}>
          &#8592; &nbsp; {traductions.app_offers_banner} {currentService}.
          </div>
        
          <div className='topbar'>
            <h1 className=''>{traductions.app_offers_banner} {currentService}.</h1>
            <div className="back-button" onClick={() => onHamburgerClick}>&#8592; {traductions.backtext}</div>
            </div>
        <iframe src={currentUrl} title="Content Frame" className="content-frame"></iframe>
      </div>
   

  );
};

export default MainContent;
