import React, { useEffect, useState } from 'react';
import './header.css';


const Header  = ({ user, traductions }) => {


  return (
    <header className="header">
      <div className="logo"> <img src={require('../../assets/images/logo-maxit.png')} alt='logo Max it'/></div>
      <div className="subscriber-info">
        <img src={require('../../assets/images/Profil.png')}alt="Avatar" className="avatar" />
        <div className='subscriber-number'>
          <div>{traductions.subscriber_number}:</div>
          <div>{user?.msisdn}</div>
        </div>
      </div>
    </header>
  );
};

export default Header;
