import React from "react";
import lionPub from './../../assets/images/pub-lion-maxit.png';
import './HomeMobile.css';
import AccessDeniedMobile from "../AccesDenied/AccessDeniedMobile";

const HomeMobile = ({ user, onOfferClick, menuItems, traductions,  accessDenied }) => {

    const handleOfferClick = (url, name) => {
        onOfferClick(url, name);
    }

    return (
        <>
            <div className="hamburger-icon">
                &#9776; &nbsp; {traductions.app_main_banner}
            </div>
            <div className='home-mobile'>
                <h1 className='tampon-title'>{traductions.bon_plan}:</h1>
                <div className='pub'>
                    <div className='pub-image'>
                        <img src={traductions.publicite_image} alt='publicite Lion Maxit' />
                    </div>
                    <div className='pub-text'>
                        <div className='pub-avatar'>
                            <img src={require('./../../assets/images/avatar-pub.png')} alt='avatar' />
                        </div>
                        <div className='pub-title'>
                            <h2>{traductions.publicite_title}</h2>
                            <p>{traductions.publicite_text}</p>
                        </div>
                    </div>
                </div>
                {
                    accessDenied
                        ? <AccessDeniedMobile traductions={traductions} />
                        : <div className='offers-section'>
                            <h1 className='tampon-title'>{traductions.offers_title_mobile}:</h1>

                            <div className='offers'>
                                {
                                    menuItems.map((item, index) => (
                                        <div key={index} className='offer' onClick={() => handleOfferClick(item.url, item.label)}>
                                            <div className='offer-image'>
                                                <img src={item.icon} alt={`Offre ${index + 1}`} />
                                            </div>
                                            <div className='offer-text'>
                                                <h2>{item.label}</h2>
                                                <p>{traductions.offers_cta}</p>
                                            </div>
                                        </div>
                                    ))
                                }


                            </div>

                        </div>
                }


            </div>
        </>
    )
}

export default HomeMobile;