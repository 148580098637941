import React, { useEffect } from "react";  
import settings from "../../config/settings";
import assistante from "./../../assets/images/icons_assistant.svg";
import faq from "./../../assets/images/icons_faq.svg";
import mapMarker from "./../../assets/images/icons_map-marker.svg";
import "./footer.css";

const Footer = ({traductions}) => {

    useEffect(() => {   
        console.log('settings LINKS:', settings.links);
    }, []);

    return (
        <footer>
            <div className="footer-container">
                <div className="footer-content">
                    <div className="footer-element">
                        <ul>
                            <li className="footer-item"><a href={settings.links.lien_faq}><img src={faq} /> &nbsp; {traductions.faq}</a></li>
                            <li className="footer-item"><a href={settings.links.lien_service_client}><img src={assistante} /> &nbsp;{traductions.call_center}</a></li>
                            <li className="footer-item"><a href={settings.links.lien_boutique}><img src={mapMarker} /> &nbsp; &nbsp;{traductions.shop}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;