import React from "react";  
import BG from './../../assets/images/assets-patterns-generic-tile.svg';
import ArrowDown from './../../assets/images/arrow-down.png';
import settings from "../../config/settings";
import './accessDenied.css';

const AccessDeniedMobile = ({traductions}) => {    

    return (
        <div className="accessdeniedMobile">
            <img className="accessdeniedBg" src={BG} alt="" />
            <div className="access-denied-content">
                <h1>{traductions.no_number_title}</h1>
                <p>{traductions.no_number_text}</p>
                <a className="accessdeniedButton" href={settings.links.bouton_android}> <img src={ArrowDown}/>  &nbsp; {traductions.buttonText}</a>
            </div>
        </div>
    );
}

export default AccessDeniedMobile;