import React from "react";
import avatarOffers from './../../assets/images/avatar-offers-desktop.svg';
import lionPub from './../../assets/images/pub-lion-maxit.png';

import './MainContentDesktop.css';
import AccessDeniedMobile from "../AccesDenied/AccessDeniedMobile";

const MainContentDesktop = ({ user, onOfferClick, menuItems, traductions, accessDenied }) => {

    const handleOfferClick = (url, name) => {
        onOfferClick(url, name);
    }

    return (
        <div className="main-content">

            <div className='home-desktop'>
                <div className='container'>
                    <div className='topbar'>
                        <h1 className=''>{traductions.app_main_banner}</h1>
                        
                    </div>
                    <h1 className='tampon-title-desktop'>{traductions.bon_plan}</h1>
                    <div className='pub-desktop'>
                        <div className='pub-image-desktop'>
                            <img src={traductions.publicite_image} alt='publicite Lion Maxit' />
                        </div>
                        <div className='pub-text-desktop'>
                            <div className='pub-avatar-desktop'>
                                <img src={require('./../../assets/images/avatar-pub.png')} alt='avatar' />
                            </div>
                            <div className='pub-title-desktop'>
                                <h2>{traductions.publicite_title}</h2>
                                <p>{traductions.publicite_text}</p>
                            </div>
                        </div>
                    </div>

                    {
                        accessDenied 
                        ? <AccessDeniedMobile traductions={traductions} />
                        : <div className='offers-section-desktop'>
                        <div className='left-section-desktop'>
                            <img
                                className="img-fluid"
                                src={avatarOffers}
                                alt="logo officiel Qatar 2022"
                            />
                        </div>

                        <div className='right-section-desktop'>
                            <h1 className='tampon-title'>{traductions.offers_title}</h1>

                            <div className='offers-desktop'>
                            {
                                menuItems.map((item, index) => (
                                    <div key={index} className='offer' onClick={() => handleOfferClick(item.url, item.label)}>
                                        <div className='offer-image'>
                                            <img src={item.icon} alt={`Offre ${index + 1}`} />
                                        </div>
                                        <div className='offer-text'>
                                            <h2>{item.label}</h2>
                                            <p>{traductions.offers_cta}</p>
                                        </div>
                                    </div>
                                ))
                            }
                                
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>

        </div>
    );

}

export default MainContentDesktop;