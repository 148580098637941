import React from "react";
import './sidebar.css';
import settings from "../../config/settings";


const menuItems = settings.menuItems;


const Sidebar = ({ user, menuItems, currentUrl, onMenuClick, traductions }) => {

    console.log('currentMenu', currentUrl);

    return (
        <div className={`sidebar`}>
            <div className="menu">
                {menuItems?.map((item, index) => (
                    <div key={index} 
                    className={`menu-item ${currentUrl == item.url ? 'active' : ''}`} 
                    onClick={() => onMenuClick(item.url, item.label) } 
                    >
                        <span className="icon"><img src={item.icon} alt="icon du service" /></span>
                        <span className="label">{item.label}</span>
                        <span className="chevron">
                                <img src={currentUrl == item.url 
                                        ? require('../../assets/images/chevron-right-active.png') 
                                        : require('../../assets/images/chevron-right.png')} 
                                        alt="" />
                        </span>

                    </div>
                ))}
            </div>
            <div className="separator"></div>
            <div className="subscriber-info">
                <img src={require('../../assets/images/Profil.png')} alt="Avatar" className="avatar" />
                <div className='subscriber-number'>
                    <div>{traductions.subscriber_number}:</div>
                    <div>{user?.msisdn}</div>
                </div>
            </div>
        </div>
    );

};

export default Sidebar;

