import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import MainContent from './components/MainContent/MainContent';
import settings from './config/settings';
import MainContentDesktop from './components/MainContentDesktop/MainContentDesktop';
import HomeMobile from './components/HomeMobile/HomeMobile';
import Footer from './components/Footer/Footer';
import isEmpty from './utils/isEmpty';
import './App.css';

const App = () => {
  const [currentUrl, setCurrentUrl] = useState(settings.menuItems[0].url);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState('obonus');
  const menuItems = settings.menuItems;
  // const traductions = settings.translations;
  const [info, setInfo] = useState(null);
  const [error, setError] = useState(null);
  const [forecasts, setForecasts] = useState([]);
  const [loading, setLoading] = useState(true);
  // les ajouts
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isContenVisible, setIsContentVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(settings.menuItems[0].label);
  // obtention de langue du Navigateur
  const browserLang = navigator.language || navigator.userLanguage;
  //Définission de la langue par défaut
  console.log('browserLang:', browserLang);
  const defaultLang = browserLang.startsWith('fr') ? 'fr' : 'en';
  const [lang, setLang] = useState(defaultLang);
  const [translations, setTranslations] = useState(settings.translations[defaultLang]);
  // Ajout un nouvel état pour gérer l'accès réfusé
  const [accessDenied, setAccessDenied] = useState(false);


  const populateWeatherData = async () => {
    const response = await fetch('weatherforecast/info');

    // if(response.status === 403){
    //   // Afficher "Accès réfusé" et retourner pour arrêter l'éxécutionde de la fonction
    //   setAccessDenied(true);
    //   return;
    // }

    const data = await response.json();
    if(isEmpty(data.msisdn)){
      setAccessDenied(true);
      console.log('les data:', {donnes:data, msisdn:data.msisdn});
      return;
    }

    setForecasts(data);
    setLoading(false);
  }

  const handleHamburgerClick = () => {

    if(isMobile){ 
      setIsContentVisible(false); // Masquer MainContent et afficher HomeMobile
    }
  }
  const handleBackbuttonClick = () => {
      console.log('click');
      setIsContentVisible(true); // Masquer MainContent et afficher HomeMobile
    
  }

  useEffect(() => {
    populateWeatherData();
  }, []);

  useEffect(() => { 
    console.log('langue:', lang);
    console.log('translations:', translations);
    console.log('isvisible:', isContenVisible);
  }, [lang]);

  // nouvelle fonction
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }

  }, []);

  const handleMenuClick = (url, name) => {
    setCurrentUrl(url);
    setCurrentPage(name);
    setIsContentVisible(true); // lorsqu'on clique sur une offre, on affiche le contenu
  };

  return (
    <div className="app">
      <Header user={forecasts}  traductions={translations}/>
      {isMobile ? (
        isContenVisible ? (
          <MainContent 
          currentUrl={currentUrl} 
          currentService={currentPage}
          traductions={translations}
          onHamburgerClick={handleHamburgerClick}/>
        ):(
          <HomeMobile
            user={forecasts}
            onOfferClick={handleMenuClick}
            menuItems={menuItems}
            traductions={translations}
            accessDenied={accessDenied}
          />
        )
      ) : (

        <div className="main">
          <Sidebar
            menuItems={menuItems}
            currentUrl={currentUrl}
            traductions={translations}
            // setCurrentMenu={handleMenuClick}
            // isOpen={isSidebarOpen}
            // setSidebarOpen={setSidebarOpen}
            onMenuClick={handleMenuClick}
            user={forecasts}
          />
          {
            isContenVisible ? (
              <MainContent 
              currentUrl={currentUrl} 
              isPhone={isMobile} 
              currentService={currentPage}
              traductions={translations}
              onBackbuttonClick={handleBackbuttonClick}
              onHamburgerClick={handleHamburgerClick}
              />
            ):(
              <MainContentDesktop 
              menuItems={menuItems} 
              onOfferClick={handleMenuClick}
              traductions={translations}
              accessDenied={accessDenied}
              />
            )
          
          }
          
        </div>

      )}
      <Footer traductions={translations} />
    </div>
  );
};

export default App;
